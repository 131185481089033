import React, {useEffect, useState} from 'react'
import Header from './header'
import Footer from './footer'
import siteContext from './siteContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.slim'

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "../styles/globals.css";
import { API_ENDPOINT } from '../util/constants';


const Layout = ({ pageTitle, children }) => {
  const [siteSettings, setSiteSettings] = useState ({});
  useEffect (()=>{
    fetch(API_ENDPOINT).then(res=>res.json()).then(res=>{ setSiteSettings(res)})
  }, [])
  return (
    <siteContext.Provider value={siteSettings} >
      {siteSettings.menus && <Header menus={siteSettings.menus} logopath={siteSettings.logo} />}
      <div className='wrapper'>
        {children}
      </div>
      {siteSettings.menus && <Footer menus={siteSettings.menus}/>}
    </siteContext.Provider>
  )
}

export default Layout