import React, {useEffect, useState } from "react";
import Layout from '../components/layout';
import ComponentLoader from '../components/componentLoader';
import { HOME_ENDPOINT } from "../util/constants";

const IndexPage = () => {

  const [pageData, setPageData] = useState([]);
  useEffect(()=>{
    if(HOME_ENDPOINT){
      const url = HOME_ENDPOINT; 
      fetch(url)
      .then(res=>res.json())
      .then(data=>{
        let pageData = []
        for(const [sec, dtls] of Object.entries(data)){
          let secDtls = {};
          if(sec==='playlists'){
            for(let j =0; j<dtls.length; j++){
              secDtls = {};
              secDtls['component'] = "playlist";
              secDtls['title'] = dtls[j]['title'];
              secDtls['className'] = "playlist";
             // secDtls['link'] = apiData.playlistLink+dtls[j]['id'];
              secDtls['collection_id'] = dtls[j]['id'];
              secDtls['link'] = "";
              secDtls['linkLabel']= 'View All';
              secDtls['data'] = dtls[j]['data'];
              pageData.push(secDtls);
            }
            
          } else {
            secDtls['component'] = "slider";
            secDtls['title'] = "";
            secDtls['className'] = "";
            secDtls['data'] = dtls;
            pageData.push(secDtls);
          }
        }
        setPageData(pageData);
      })
    }
  },[])
  
  return (
    <Layout pageTitle="Home Page">
      <ComponentLoader comp={pageData} />
    </Layout>
  )
}

export default IndexPage

export const Head = () => <title>Home Page</title>
